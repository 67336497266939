import React, { useEffect, useState } from 'react';

import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import styled from 'styled-components';
import './styles.scss';

import { Divider } from 'lib/Divider';
import numberFormatService from 'services/numberFormatService';
import { BREAKPOINT_TABLET_PX, BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import theme from 'config/theme';

const { colors } = theme;

const fee = 0.035;

export const Calculator = () => {
	const [rental, setRental] = useState(5000);
	const [leaseTerm, setLeaseTerm] = useState(12);
	const [multipleUnits, setMultipleUnits] = useState(false);
	const [units, setUnits] = useState(1);

	useEffect(() => {
		if (!multipleUnits) {
			setUnits(1);
		}
	}, [multipleUnits]);

	return (
		<Container>
			<CardContainer>
				<Card>
					<Row>
						<Label>Monthly rental</Label>
						<Value>{numberFormatService.formatCurrency(rental, 'R')}</Value>
					</Row>
					<RangeContainer>
						<InputRange
							maxValue={20000}
							minValue={1000}
							step={500}
							value={rental}
							onChange={value => setRental(Number(value))}
						/>
					</RangeContainer>
					<Row>
						<Label>Lease term</Label>
						<Value>
							{leaseTerm} month{leaseTerm === 1 ? '' : 's'}
						</Value>
					</Row>
					<RangeContainer>
						<InputRange
							maxValue={24}
							minValue={1}
							value={leaseTerm}
							onChange={value => setLeaseTerm(Number(value))}
						/>
					</RangeContainer>
					<Row style={{ marginBottom: 16 }}>
						<Label>Do you have multiple rooms like this?</Label>
					</Row>
					<Row
						onClick={() => setMultipleUnits(true)}
						style={{ marginBottom: 16, cursor: 'pointer' }}
					>
						<Value>Yes</Value>
						<OutlinedCircle>{multipleUnits && <Circle />}</OutlinedCircle>
					</Row>
					<Row
						onClick={() => setMultipleUnits(false)}
						style={{ marginBottom: 16, cursor: 'pointer' }}
					>
						<Value>No</Value>
						<OutlinedCircle>{!multipleUnits && <Circle />}</OutlinedCircle>
					</Row>
					{multipleUnits && (
						<>
							<Row>
								<Label>Rooms available</Label>
								<Value>
									{units} unit{units === 1 ? '' : 's'}
								</Value>
							</Row>
							<RangeContainer>
								<InputRange
									maxValue={30}
									minValue={1}
									value={units}
									onChange={value => setUnits(Number(value))}
								/>
							</RangeContainer>
						</>
					)}
					<Divider />
					<Row>
						<Label>You will earn</Label>
						<Title style={{ color: colors.darkTurquoise }}>
							{numberFormatService.formatCurrency(
								Number((leaseTerm * rental * units).toFixed(0)),
								'R',
							)}
						</Title>
					</Row>
				</Card>
			</CardContainer>
			<BreakdownSection>
				<SubTitle>Breakdown</SubTitle>
				<Row>
					<p>Monthly rental:</p>
					<p>{numberFormatService.formatCurrency(rental, 'R')}</p>
				</Row>
				<Row>
					<p>Lease term:</p>
					<p>
						{leaseTerm} month{leaseTerm === 1 ? '' : 's'}
					</p>
				</Row>
				<Label style={{ marginTop: 16 }}>
					You will earn {numberFormatService.formatCurrency(leaseTerm * rental, 'R')} from this
					lease
				</Label>
				<Divider />
				{multipleUnits && (
					<>
						<Row>
							<p>Units available:</p>
							<p>
								{units} unit{units === 1 ? '' : 's'}
							</p>
						</Row>
						<Label style={{ marginTop: 16 }}>
							You will earn {numberFormatService.formatCurrency(leaseTerm * rental * units, 'R')}{' '}
							from these leases.
						</Label>
					</>
				)}
			</BreakdownSection>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	margin-bottom: 48px;
	margin-top: 24px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
`;

const CardContainer = styled.div`
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		width: 100%;
		padding: 24px;
		padding-top: 0;
	}
`;

const Card = styled.div`
	padding: 48px;
	border: 1px solid ${colors.grey10};
	border-radius: 16px;
	max-width: 500px;
	background: ${colors.white};

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding: 0px;
		border: none;
	}
`;

const BreakdownSection = styled.div`
	margin: 48px;
	width: 300px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		margin: 24px;
		width: 100%;
		padding: 24px;
		padding-top: 0;
	}
`;

const OutlinedCircle = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 2px solid ${colors.pink};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Circle = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: ${colors.pink};
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
`;

const Label = styled.div`
	color: ${colors.darkBlue};
	font-weight: 600;
	font-size: 14px;
`;

const Value = styled.div`
	color: ${colors.grey60};
	font-weight: 600;
	font-size: 14px;
`;

const Title = styled.div`
	font-size: 20px;
	color: ${colors.darkBlue};
	font-weight: 600;
`;

const SubTitle = styled.div`
	font-size: 18px;
	color: ${colors.darkBlue};
	font-weight: 600;
	margin-bottom: 16px;
`;

const RangeContainer = styled.div`
	margin-top: 24px;
	margin-bottom: 24px;
`;
