import React from 'react';

import theme from 'config/theme';
import styled from 'styled-components';
import { Button } from 'lib/Button';
import routes from 'config/routes';
import useRouter from 'hooks/useRouter';
import { BREAKPOINT_TABLET_PX, BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

const { colors } = theme;

interface RevenueGrantedSectionProps {
	isLandlord?: Boolean;
}

const RevenueGrantedSection = ({ isLandlord }: RevenueGrantedSectionProps) => {
	const [goTo] = useRouter();

	return (
		<Container>
			<Number>R 100 000 000+</Number>
			<SubHeading>in concluded lease value since 2018</SubHeading>
			{isLandlord ? (
				<Button
					isPink
					link={routes.create_listing}
					onClick={e => {
						e.stopPropagation();
						e.preventDefault();
						goTo(routes.create_listing);
					}}
				>
					Get started
				</Button>
			) : (
				<Button isPink link={routes.create_listing}>
					Get started
				</Button>
			)}
		</Container>
	);
};

export default RevenueGrantedSection;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
	flex-direction: column;
	text-align: center;
	background: linear-gradient(180deg, ${colors.darkTurquoiseFaded} 0%, ${colors.white} 100%);
	padding: 48px 16px;
	gap: 32px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: none;
	}
`;

const Number = styled.div`
	font-size: 72px;
	font-weight: 700;
	margin-bottom: 16px;
	letter-spacing: 2px;
`;

const SubHeading = styled.div`
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 24px;
`;
