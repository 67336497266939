import React, { useState, Component } from 'react';

import styled from 'styled-components';

import CollapsibleSection from '../../faqs/CollapsibleSection';
import { Faq } from './types';
import { faqData } from './faqData';
import theme from 'config/theme';

const { colors } = theme;

const FAQContainer = () => {
	const [expandedKey, setExpandedKey] = useState('');
	const handleClick = (faq: Faq) => {
		setExpandedKey(prev => (prev === faq.question ? '' : faq.question));
	};

	return (
		<Container>
			{faqData.map(faq => (
				<CollapsibleSection
					header={faq.question}
					key={faq.question}
					expand={faq.question === expandedKey}
					onClick={() => handleClick(faq)}
				>
					<AnswerText dangerouslySetInnerHTML={{ __html: faq.answer }} />
				</CollapsibleSection>
			))}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	text-align: flex-start;
	flex-direction: column;
	background: ${colors.white};
	gap: 16px;
`;

const AnswerText = styled.p`
	color: ${colors.darkBlue};

	a {
		color: ${colors.linkBlue};
		font-weight: bold;
	}

	ul {
		list-style: disc;

		> li {
			list-style: disc;
			margin-left: 24px;
		}
	}
`;

export default FAQContainer;
