import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';

import ChatSupport from 'assets/images/landing/img-chat-support.png';
import TenantDetails from 'assets/images/landing/img-tenant-details.png';
import InboxEnquiry from 'assets/images/landing/img-inbox-enquiry.png';
import { ReactComponent as Checkmark } from 'assets/images/landing/checkmark.svg';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { ReactComponent as SectionBreak } from 'assets/images/landing/SectionBreak.svg';

const { colors, fontSizes, fontWeights } = theme;

const LandlordValueProposition = () => (
	<>
		<Container>
			<CardContainer>
				<Card>
					<CardTitle>Receive comprehensive applicant information</CardTitle>
					<Text>
						Prefilled applicant information allows you to pre-vet prospective tenants. Finding the
						perfect tenant to fit into your availabilty.
					</Text>
					<BulletContainer>
						<BulletItem>
							<Checkmark />
							<BulletText>Personal details</BulletText>
						</BulletItem>
						<BulletItem>
							<Checkmark />
							<BulletText>Educational information</BulletText>
						</BulletItem>
						<BulletItem>
							<Checkmark />
							<BulletText>Lease details</BulletText>
						</BulletItem>
					</BulletContainer>
				</Card>
				<Card>
					<img src={TenantDetails} />
				</Card>
			</CardContainer>
			<CardContainerRev>
				<Card>
					<img src={ChatSupport} />
				</Card>
				<Card>
					<CardTitle>Full time support team</CardTitle>
					<Text>
						Our full time support team at DigsConnect follows up with tenants from the moment you
						send your first reply until they have successfully signed and moved in.
					</Text>
					<Text>
						We make sure that the students have everything they need from you to make their
						decision, fast.
					</Text>
				</Card>
			</CardContainerRev>
			<CardContainer>
				<Card>
					<CardTitle>All your enquiries in one place</CardTitle>
					<Text>
						An on-platform chat system allowing you to converse to potential tenants and gather all
						you need to finalize that move-in.
					</Text>
					<BulletContainer>
						<BulletItem>
							<Checkmark />
							<BulletText>Chat to potential tenants</BulletText>
						</BulletItem>
						<BulletItem>
							<Checkmark />
							<BulletText>Share documents & images</BulletText>
						</BulletItem>
						<BulletItem>
							<Checkmark />
							<BulletText>In-chat support</BulletText>
						</BulletItem>
					</BulletContainer>
				</Card>
				<Card>
					<img src={InboxEnquiry} />
				</Card>
			</CardContainer>
		</Container>
		<StyledSectionBreak />
	</>
);

export default LandlordValueProposition;

const CardTitle = styled.h2`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.xxlarge};
	text-align: left;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		font-size: ${fontSizes.xlarge};
		width: 100%;
	}
`;

const CardContainerRev = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 48px;
	gap: 60px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column-reverse;
		gap: 24px;
		align-items: center;
    padding: 16px;
	}
`;

const CardContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 48px;
	gap: 60px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
		gap: 24px;
		align-items: center;
    padding: 16px;
	}
`;

const Card = styled.div`
	display: flex;
	background: ${colors.white};
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	background: ${colors.white};
	align-items: center;
	padding-top: 24px;
	gap: 16px;
	max-width: 1060px;
	margin: auto;
`;

const BulletText = styled.p`
	font-size: 20px;
	font-weight: ${fontWeights.bold};
	color: ${colors.darkBlue};
	align-items: center;
	max-width: 500px;
`;
const Text = styled.p`
	font-size: 20px;
	color: ${colors.darkBlue};
	align-items: center;
	max-width: 500px;
`;

const BulletContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 12px;
`;
const BulletItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
`;

const StyledSectionBreak = styled(SectionBreak)`
  display: block;
`;
