import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import api from 'api';
import theme from 'config/theme';
import Main from 'components/common/main';
import AcademicPartners from 'components/common/academic-partners';
import Footer from 'components/common/footer';
import Header from 'components/common/header';
import { PageMeta } from 'constants/page_meta';
import routes from 'config/routes';
import { imgixLandlordLandingUrl } from 'config/imgix';
import { Button } from 'lib/Button';
import { analyticsService, PageName } from 'services/analyticsService';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';
import { ReactComponent as PlayIcon } from 'assets/images/icons/play.svg';

import {
	BgImg,
	ImageContainer,
	DesktopHero,
	HeroContainer,
	BannerTextOverlay,
	BannerTitleText,
	BannerImage,
	MobileImage,
	MobileBanner,
	MobileButtonContainer,
	TestimonialLinkAndCreateListingContainer,
	TestimonialVideoButton,
	Text,
	MobileLandlordBannerContent,
	MobileLandlordTitleContainer,
	MobileLandlordTitle,
} from './styles';
import { VarsityCollegeBanner } from './VarsityCollegeBanner';
import ConnectionsCounter from './ConnectionsCounter';
import RevenueGrantedSection from './RevenueGrantedSection';
import InfoBlocksSection from './InfoBlocksSection';
import PricingBlocksSection from './PricingBlocksSection';
import { howItWorksProps, howPricingWorksProps } from './landingContent';
import PricingCalculatorSection from './PricingCalculatorSection';
import LandlordResourcesSection from './LandlordResourcesSection';
import LandlordValueProposition from './LandlordValueProposition';
import LandlordVideoTestimonialSection from './LandlordVideoTestimonialSection';
import LandlordFAQ from './LandlordFAQ';
import useRouter from 'hooks/useRouter';
import HowItWorks from './HowItWorks';

const { colors } = theme;

const meta = PageMeta.getStatic('landing');

const width =
	window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const LandingHero = () => {
	const [goTo] = useRouter();
	return (
		<HeroContainer>
			<DesktopHero>
				<BannerImage>
					<ImageContainer>
						<BannerTextOverlay noFade>
							<BannerTitleText>
								90 000+ tenant to landlord connections.
								<br />
								Find your ideal tenant today!
							</BannerTitleText>
							<TestimonialLinkAndCreateListingContainer>
								<Button
									noMargin
									isPink
									onClick={e => {
										e.stopPropagation();
										e.preventDefault();
										goTo(routes.create_listing);
									}}
								>
									+ Create your free listing
								</Button>
								<TestimonialVideoButton
									onClick={() => {
										modalContainer.set(ModalType.LandlordLandingTestimony);
									}}
								>
									<PlayIcon />
									<Text> Hear what other landlords say </Text>
								</TestimonialVideoButton>
							</TestimonialLinkAndCreateListingContainer>
						</BannerTextOverlay>
						<BgImg src={`${imgixLandlordLandingUrl}?auto=compress&auto=format&h=700&w=${width}`} />
					</ImageContainer>
				</BannerImage>
			</DesktopHero>
			<MobileBanner>
				<MobileImage src={`${imgixLandlordLandingUrl}?auto=compress&auto=format&h=420`} />
				<MobileLandlordBannerContent>
					<MobileLandlordTitleContainer>
						<MobileLandlordTitle>
							90 000+ tenant to landlord connections.
							<br />
							Find your ideal tenant today!
						</MobileLandlordTitle>
					</MobileLandlordTitleContainer>
					<TestimonialLinkAndCreateListingContainer>
						<MobileButtonContainer>
							<Button
								noMargin
								isPink
								link={routes.create_listing}
								onClick={e => {
									e.stopPropagation();
									e.preventDefault();
									goTo(routes.create_listing);
								}}
							>
								<Text>+ Create your free listing</Text>
							</Button>
						</MobileButtonContainer>
						<TestimonialVideoButton
							onClick={() => {
								modalContainer.set(ModalType.LandlordLandingTestimony);
							}}
						>
							<PlayIcon />
							<Text> Hear what other landlords say </Text>
						</TestimonialVideoButton>
					</TestimonialLinkAndCreateListingContainer>
				</MobileLandlordBannerContent>
			</MobileBanner>
		</HeroContainer>
	);
};

const LandlordLanding = () => {
	const [connectionsCount, setConnectionsCount] = useState(0);

	useEffect(() => {
		analyticsService.trackPage(PageName.LandingLandlord);
		api.bookings
			.getConnectionCounter()
			.then(({ data }) => setConnectionsCount(data.total_count))
			.catch(() => {});
	}, []);

	return (
		<>
			<Main {...{ bg: colors.white }}>
				<Helmet>
					<title>{meta.title}</title>
					<meta name="description" content={meta.desc} />
				</Helmet>
				<Header
					variant="light"
					color={colors.white}
					bgColor={colors.transparent}
					isLanding
					searchable
				/>
				<LandingHero />
				<AcademicPartners background={colors.darkTurquoiseFaded} />
				<LandlordValueProposition />
				{!!connectionsCount && <ConnectionsCounter count={connectionsCount} />}
				<PricingBlocksSection />
				<RevenueGrantedSection isLandlord />
				<PricingCalculatorSection />
				<LandlordVideoTestimonialSection />
				<VarsityCollegeBanner />
				<LandlordResourcesSection />
				<LandlordFAQ />
				<Footer />
			</Main>
		</>
	);
};

export default LandlordLanding;
