// NOTE: Testimonials taken from https://www.instagram.com/digsconnect/

const testimonials = [
	{
		id: '1',
		profilePicture: '',
		firstName: 'Catherine',
		lastName: 'Hermans',
		testimonial:
			'Digsconnect has come a long way from what it was two years ago, I love the brand and all the help I`ve gotten with leasing my student homes and converting my business to a Digs. I get loyal students who are staying with me until they finish their studies.',
	},
	{
		id: '2',
		profilePicture: '',
		firstName: 'Juliette',
		lastName: 'Souchon',
		testimonial: `My experience with Digsconnect has been fantastic, I love the service and the leads sure keep coming`,
	},
	{
		id: '3',
		profilePicture: '',
		firstName: 'Annette',
		lastName: 'De Weijer',
		testimonial:
			'I want  to extend a big `Thank You` for your hard work and especially hands on, as well as your willingness to go the extra mile  in getting tenants for our student accommodation in Mowbray, Cape Town. Thanks again Ashley!',
	},

	{
		id: '4',
		profilePicture: '',
		firstName: 'Juliette',
		lastName: 'Souchon',
		testimonial:
			'It`s run by people who clearly also understand campus life and student needs. I get to choose the right mix of students because of this small team of hard working creatives',
	},
	{
		id: '5',
		profilePicture: '',
		firstName: 'John, MD of Respublica',
		lastName: '',
		testimonial:
			'DigsConnect have helped Respublica showcase our properties across our national footprint with much success. Their website and energy ensure that students can find accommodation across multiple geographies that meets their needs and pocket.',
	},
];

export default testimonials;
