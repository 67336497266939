import React from 'react';

import styled from 'styled-components';

import { Calculator } from 'components/pricing/Calculator';
import theme from 'config/theme';

import { SectionTitle } from './styles';

const { colors } = theme;

const PricingCalculatorSection = () => (
	<Container>
		<SectionTitle>Estimate how much you could earn</SectionTitle>
		<Calculator />
	</Container>
);

export default PricingCalculatorSection;

const Container = styled.div`
	padding: 24px 16px 16px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${colors.white};
`;
