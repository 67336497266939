import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import FAQContainer from './faqs/FAQContainer';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

const { colors } = theme;

const LandlordFAQ = () => {
	return (
		<Container>
			<Title>Frequently Asked Questions</Title>
			<FAQContainer />
		</Container>
	);
};

export default LandlordFAQ;

const Container = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	background: ${colors.white};
	padding: 48px 16px;
	gap: 16px;
	margin: auto;
	width: 80%;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		width: 100%;
	}
`;

const Title = styled.div`
	font-size: 40px;
	font-weight: 700;
	color: ${colors.darkBlue};
`;
