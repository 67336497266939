import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { Button } from 'lib/Button';
import routes from 'config/routes';
import { imgixVarsityCollegeLogoUrl } from 'config/imgix';
import { ReactComponent as RightArrow } from 'components/icons/arrow-right.svg';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

const { colors } = theme;

export const VarsityCollegeBanner = () => (
	<Container>
		<Logo src={imgixVarsityCollegeLogoUrl}></Logo>
		<SubHeading>
			DigsConnect is the official accommodation partner for The IIE's Varsity College.
		</SubHeading>
		<>
			<InfoContainer>
				<InfoSubContainer>
					<InfoHeading>8</InfoHeading>
					<InfoText>campuses</InfoText>
				</InfoSubContainer>
				<InfoSubContainer>
					<InfoHeading>15 000+</InfoHeading>
					<InfoText>students</InfoText>
				</InfoSubContainer>
			</InfoContainer>
			<LinkTextContainer>
				<LinkText
					href={'https://storage.googleapis.com/digsconnect-static/Letter%20to%20landlords.pdf'}
					target={'_blank'}
					rel={'noopener noreferrer'}
				>
					Letter from the MD of The IIE's Varsity College
				</LinkText>
				<RightArrow />
			</LinkTextContainer>
		</>
	</Container>
);

const Logo = styled.img`
	width: 250px;
	margin-bottom: 40px;
`;

const Text = styled.p`
	color: ${colors.grey60};
	margin-bottom: 40px;
`;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	background: ${colors.white};
	padding: 60px 16px;
`;

const InfoContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	gap: 8px;
`;

const InfoSubContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 8px 8px;
`;

const InfoHeading = styled.div`
	font-size: 16px;
	font-weight: 700;
	color: ${colors.darkBlue};
`;

const InfoText = styled.p`
	font-size: 14px;
	font-weight: 400;
	color: ${colors.darkBlue};
`;

const SubHeading = styled.div`
	font-size: 25px;
	font-weight: 700;
	margin-bottom: 24px;
	max-width: 520px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		font-size: 20px;
	}
`;

const LinkTextContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-weight: 700;
	color: ${colors.linkBlue};
	margin-top: 24px;
`;

const LinkText = styled.a`
	color: ${colors.linkBlue};
	font-size: 16px;
`;
