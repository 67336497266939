import React from 'react';

import styled from 'styled-components';

import Carousel from 'components/common/carousel';
import testimonials from 'config/testimonials';
import theme from 'config/theme';

import { Video } from 'lib/Video';
import { BREAKPOINT_TABLET_PX, BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { useScreenWidth } from 'hooks/useScreenWidth';
const { colors } = theme;

const containerStyle = {
	backgroundColor: colors.lightGray,
	height: '100%',
	width: '100%',
};

const LandlordVideoTestimonialSection = () => {
	const width = useScreenWidth();
	const videoWidth = width / 3;
	return (
		<Container>
			<TitleContainer>Testimonials</TitleContainer>
			<SubTitleInfoContainer>
				Hear what other landlords have to say about DigsConnect
			</SubTitleInfoContainer>
			<TestimonialContainer>
				<CarouselVideoContainer>
					<Video
						videoUrl="https://www.youtube.com/embed/uZh4BKfEMjE"
						width={videoWidth.toString + 'px'}
					/>
				</CarouselVideoContainer>
				<CarouselContainer>
					<Carousel delay={5000} positionMarkerColor={colors.darkBlue}>
						{testimonials.map(({ firstName, lastName, testimonial, id }) => (
							<CarouselTextContainer key={id}>
								<TestimonialText>“{testimonial}”</TestimonialText>
								<TestimonialAuthText>
									- {firstName} {lastName}
								</TestimonialAuthText>
							</CarouselTextContainer>
						))}
					</Carousel>
				</CarouselContainer>
			</TestimonialContainer>
		</Container>
	);
};

export default LandlordVideoTestimonialSection;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: ${colors.darkTurquoiseFaded};
	padding: 70px 150px;
	gap: 20px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		gap: 10px;
		padding: 20px 50px;
	}
`;

const TitleContainer = styled.div`
	text-align: center;
	font-size: 32px;
	font-weight: 700;
	color: ${colors.darkBlue};
`;

const SubTitleInfoContainer = styled.div`
	text-align: center;
	font-size: 20px;
	font-weight: 400;
	color: ${colors.darkBlue};
`;

const TestimonialContainer = styled.div`
	display: flex;
	flex-flow: row-wrap;
	gap: 20px;
	align-itmes: flex-start;
	justify-content: center;
	width: 95%;
	max-width: 1500px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		flex-direction: column;
		align-itmes: flex-start;
		justify-content: flex-start;
		height: 550px;
	}
`;

const CarouselVideoContainer = styled.div`
	height: 380px;
	width: 100%;
	margin-bottom: 10px;
	border-radius: 16px;
	justify-content: center;
	max-width: 750px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		height: 200px;
	}
`;
const CarouselContainer = styled.div`
	height: 360px;
	width: 100%;
	margin-bottom: 10px;
	margin-left: 16px;
	justify-content: center;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		height: 300px;
		align-items: center;
	}
`;

const CarouselTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	color: ${colors.darkBlue};
`;

const TestimonialText = styled.div`
	font-size: 20px;
	color: ${colors.darkBlue};
	line-height: 1.2;
	margin-bottom: 24px;
`;

const TestimonialAuthText = styled.div`
	font-size: 20px;
	font-weight: 700;
	align-items: flex-start;
	color: ${colors.darkBlue};
	justify-content: flex-start;
`;
