import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { Button } from 'lib/Button';
import routes from 'config/routes';

import { SectionTitle } from './styles';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

const { colors } = theme;

const LandlordResourcesSection = () => (
	<Container>
		<SectionTitle>Landlord resources</SectionTitle>
		<Text>
			We’ve put together some resources and useful tips to help you better understand how our
			platform works. After all, we want your listings to do as well as possible: what helps you,
			helps us.
		</Text>
		<ButtonContainer>
			<Button isOutline whiteBackground link={routes.landlord_resources}>
				View landlord resources
			</Button>
		</ButtonContainer>
		<ButtonContainer>
			<Button link={routes.contact_us}>Contact us</Button>
		</ButtonContainer>
	</Container>
);

export default LandlordResourcesSection;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	background: ${colors.darkTurquoiseFaded};
	padding: 48px 16px;
`;

const ButtonContainer = styled.div`
	margin-bottom: 24px;
	width: 100%;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		width: 80%;
	}
`;

const Text = styled.p`
	font-size: 20px;
	color: ${colors.darkBlue};
	margin-bottom: 40px;
	margin-left: 16px;
	margin-right: 16px;
	max-width: 500px;
`;
